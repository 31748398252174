import { Manager } from '../model/Manager';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { CountryCodeService } from './country-code.service';
import { UtilityService } from './utility.service';
import { baseResponseObject } from '../configs/request.config';

@Injectable({
  providedIn: 'root'
})
export class ManagerService {
  private url = '/manager';
  public addresses: BehaviorSubject<Manager[]> = new BehaviorSubject<Manager[]>([])

  constructor(
    private api: ApiService,
    private utilityService: UtilityService,
    ) { }

  public newEmptyManager(): Manager {
    return {
      id: '', name: '',email:'',telephone:''
    }
  }

  public createManagerFromRequest(content: any): Manager{
    if (!content)
      return this.newEmptyManager();

    return {
      id: content.id,
      name: content.name,
      email:content.email,
      telephone:content.telephone
    };
  }

  delete(manager: Manager) {
    const formData = new FormData();
    formData.append('mangaer_id', manager.id);

    return this.api.delete(this.url, formData, true);
  }

  get(manager: Manager) {
    const formData = new FormData();
    formData.append('manager_id', manager.id);

    return this.api.get(this.url, formData, true);
   }

  
  post(manager: Manager) {
    const formData = new FormData();
    formData.append('name', manager.name);
    formData.append('telephone', manager.telephone);
    formData.append('email', manager.email);


    return this.api.post(this.url, formData, true);
  }

  put(manager: Manager) {
    const formData = new FormData();
    formData.append('manager_id', manager.id);
    formData.append('name', manager.name);
    formData.append('telephone', manager.telephone);
    formData.append('email', manager.email);


    return this.api.put(this.url, formData, true);
  }

  selectRequestFunctionManager(manager: Manager){
    if(manager.id){
      return this.put(manager);
    }
    return this.post(manager);
  }

  validateManager(manager: Manager): boolean{
    var check = true;
    
    if(!manager){
      check = false;
    }

    check &&= this.validateName(manager);
    check &&= this.validateTelephone(manager);

    return check;

  }

  validateName(manager): boolean{
    return manager.name && manager.name != '';
  }

  validateTelephone(manager): boolean {
    return this.utilityService.validateString(manager.telephone, 1, 20, [], true);
  }


}



