import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { Address } from "../model/Address";
import { Manager } from "../model/Manager";
import { BusinessTypes } from "../model/BusinessTypes";
import { Company } from "../model/Company";
import { Customer } from "../model/Customer";
import { AddressService } from "./address.service";
import { ManagerService } from "./manager.service";
import { ApiService } from "./api.service";
import { BaseControlsService } from "./base-controls.service";
import { UtilityService } from "./utility.service";
import { baseResponseObject } from "../configs/request.config";

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private url = '/company';

  companies: BehaviorSubject<Company[]> = new BehaviorSubject<Company[]>([]);
  currencyTypes: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  businessTypes: BehaviorSubject<BusinessTypes[]> = new BehaviorSubject<
    BusinessTypes[]
  >([]);
  selectedCompany: BehaviorSubject<Company> = new BehaviorSubject<Company>(
    this.newEmptyCompany()
  );
  pendingDownloadSaft: boolean = false;

  constructor(
    private api: ApiService,
    private addressService: AddressService,
    private managerService: ManagerService,
    private baseControlsService: BaseControlsService,
    private utilityService: UtilityService
  ) {
    // TEST
    // this.getCompanyCurrencyType();
    // this.getCompanyBusinessType();


    // this.getAll().subscribe()
    this.selectedCompany.subscribe((company) => {
      this.baseControlsService.isLoading.next(true);
      setTimeout((_) => this.baseControlsService.isLoading.next(false), 0);
    });
  }

  billingMethod: { value: string; label: string }[] =
    this.createBillingMethod();

  createBillingMethod(): { value: string; label: string }[] {
    const language = 'pt'
    const unitOfMeasure = {

      pt: [
        { value: 'mensal', label: 'Mensal' },
        { value: 'trimestral', label: 'Trimestral' },
        { value: 'anual', label: 'Anual' },
      ],
    };
    return unitOfMeasure[language];
  }



  shippingMethod: { value: string; label: string }[] =
    this.createShippingMethod();

  createShippingMethod(): { value: string; label: string }[] {
    const language = 'pt'
    const unitOfMeasure = {

      pt: [
        { value: 'ctt', label: 'CTT' },
        { value: 'email', label: 'Email' },
      ],
    };
    return unitOfMeasure[language];
  }

  bankMethod: { value: string; label: string}[] =
    this.createBankMethod();

  createBankMethod(): { value:string, label: string }[] {
    const language = 'pt'
    const unitOfMeasure = {

      pt : [
        { value: '', label: ''},
        { value: 'bancoCtt', label: 'Banco CTT'},
        { value: 'novoBanco', label: 'Novo Banco'},
        { value: 'santander', label: 'Banco Santander Totta' },
        { value: 'bcp', label: 'Banco Comercial Português - Millennium BCP' },
        { value: 'cgd', label: 'Caixa Geral de Depósitos' },
        { value: 'bpi', label: 'Banco BPI' },
        { value: 'montepio', label: 'Banco Montepio' },
        { value: 'creditoAgricola', label: 'Crédito Agrícola' },
        { value: 'activobank', label: 'ActivoBank'},
        { value: 'abanca', label: 'Abanca'},
        { value: 'bankinter', label: 'Bankinter' },        
        { value: 'bancoBest', label: 'Banco Best' },
        { value: 'bancoInvest', label: 'Banco Invest'},
        { value: 'bancoFinantia', label: 'Banco Finantia'},
        { value: 'bancoAtlanticoEuropa', label: 'Banco Atlântico Europa'},
        { value: 'Outro', label: 'Outro'},
      ],
    
    };
    return unitOfMeasure[language];
  }
  
  eacMethod: { value: string; label: string }[] =
  this.createEacMethod();

  createEacMethod(): { value: string; label: string }[] {
  const language = 'pt'
  const unitOfMeasure = {

    pt: [
      { value: '49320', label: '49320 - Transporte Ocasional De Passageiros Em Veículos Ligeiros (TÁXI)' },
      { value: 'Outro', label: 'Outro' },
    ],

  };
  return unitOfMeasure[language];
  }

  businessNameMethod: { value: string; label: string }[] =
  this.createBusinessNameMethod();

  createBusinessNameMethod(): { value: string; label: string }[] {
  const language = 'pt'
  const unitOfMeasure = {
    pt: [
      { value: 'Cooperativa', label: 'Cooperativa' },
      { value: 'Sociedade Civil', label: 'Sociedade Civil' },
      { value: 'Sociedade Por Quotas', label: 'Sociedade Por Quotas' },
      { value: 'Sociedade em Nome Coletivo', label: 'Sociedade em Nome Coletivo' },
      { value: 'Individual', label: 'Individual' },
    ],

  };
  return unitOfMeasure[language];
  }



  obsMethod: { value: string; label: string }[] =
    this.createObsMethod();

    createObsMethod(): { value: string; label: string }[] {
    const language = 'pt'
    const unitOfMeasure = {

      pt: [
        { value: 'Cancelamento a Pedido do Cliente', label: 'Cancelamento a Pedido do Cliente' },
        { value: 'Venda da Licença', label: 'Venda da Licença' },
        { value: 'Falta de Pagamento', label: 'Falta de Pagamento' },
        { value: 'Outro', label: 'Outro' },
      ],

    };
    return unitOfMeasure[language];
  }


  public ivaReport(startDate: any, endDate: any, warehouseId?: any, documentType?: any): any {
    const url = '/company/iva_report';
    const formData = new FormData();
    console.log("Processed dates:", startDate, endDate);
    console.log("Invoice type:", documentType);
    console.log("Warehouse ID:", warehouseId);
    if (startDate === 0) {
      endDate = new Date();
      startDate = 0;
    } else if (startDate == null && endDate == null) {
      endDate = new Date();
      startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
      console.log("Default case:", startDate, endDate);
    } else {
      console.log("last case:", startDate, endDate);
      startDate = new Date(startDate.year, startDate.month - 1, startDate.day);
      endDate = new Date(endDate.year, endDate.month - 1, endDate.day);
  
      // Set the endDate to 23:59:59
      endDate.setHours(23, 59, 59, 999);
    }
  
    formData.append('company_id', this.selectedCompany.getValue().companyId);
    if (startDate === 0) {
      // Special case for no start date
      formData.append('start_ts', '0');
    } else {
      formData.append('start_ts', Math.floor((startDate as Date).getTime() / 1000).toString());
    }
  
    formData.append('end_ts', Math.floor(endDate.getTime() / 1000).toString());
  
    if (warehouseId) {
      formData.append('warehouse_id', warehouseId);
    }
  
    if (documentType == "FT" || documentType == "FR" || documentType == "FS" || documentType == "NC" || documentType == "RG") {
      formData.append('document_type', documentType);
    }
    return this.api.postPdf(url, formData, true);
  }
  
  

  public getFiltered(
    minLimit,
    maxLimit,
    orderedList?,
    textFilter?,
    filter_type?
  ) {
    const url = '/companies_filtered';
    const formData = new FormData();

    return this.api
      .getFiltered(
        url,
        formData,
        minLimit,
        maxLimit,
        orderedList,
        textFilter,
        filter_type
      )
      .pipe(
        map((resp: any) => {
          const { companies_number, companies } = resp[baseResponseObject];
          const companiesObj = companies.map((c) =>
            this.createCompanyFromRequest(c)
          );

          return {
            companies_number,
            companies: companiesObj,
          };
        })
      );
  }

  public newEmptyCompany(): Company {
    return {
      address: this.addressService.newEmptyAddress(),
      bank: '',
      businessName: '',
      conservatory: '',
      createdDate: new Date(0),
      currencyCode: '',
      eacCode: '',
      email: '',
      obs: '-',
      iban: '',
      companyId: '',
      imageUpdate: 0,
      isGeneratingSaft: false,
      logo: '',
      name: '',
      nif: '',
      permanentCertificate: '',
      shareCapital: 0,
      shippingAddress: this.addressService.newEmptyAddress(),
      manager: this.managerService.newEmptyManager(),
      swift: '',
      telephone: '',
      website: '',
      billing_method:'',
      shipping_method:'',
      modifiedDate: new Date(0),
      lastDocumentDate:new Date(0),
      showEmail: true,
      showTelephone: true,
      showIban: true,
    };
  }

  private _compareCompanyAddress(
    company1: Company,
    company2: Company
  ): boolean {
    if (!this.addressService.compareAddress(company1.address, company2.address))
      return false;

    if (
      !this.addressService.compareAddress(
        company1.shippingAddress,
        company2.shippingAddress
      )
    )
      return false;

    return true;
  }

  public compareCompany(company1: Company, company2: Company): boolean {
    const parameters = Object.keys(company1).filter((key) => [
      'address',
      'shippingAddress',
    ]);

    if (!company2) return false;

    if (!this._compareCompanyAddress(company1, company2)) return false;

    for (let parameter of parameters) {
      if (company1[parameter] != company2[parameter]) return false;
    }

    return true;
  }

  private createString(str: string): string {
    if (!str) {
      return '';
    }
    return str;
  }

  private createBoolean(bool: boolean): boolean {
    // if (!bool) {
    //   return false;
    // }
    return !bool;
  }
  private _createDateTimeFromTimestamp(timestamp: number): Date {
    const minLength = 12;
    if (!timestamp) return new Date(0);
    if ((timestamp + '').length < minLength) return new Date(timestamp * 1000);
    return new Date(timestamp);
  }

  private _createNumber(num: number) {
    if (!num) return 0;
    return num;
  }

  private createDateTime(timestamp: number) {
    const minLength = 12;
    //if (!timestamp) return new Date(0);
    if ((timestamp + '').length < minLength) {
      const d=new Date(timestamp * 1000);
      return `${d.getDate()}/${(d.getMonth()+1)}/${d.getFullYear()}`;
    }
    //return new Date(timestamp);
  }
  // COMPANY PARAMETERS CREATE FUNCTIONS

  private _createAddress(companyInfo: any): Address {
    return this.addressService.createAddressFromRequest(companyInfo.address);
  }
  private _createBank(companyInfo: any): string {
    return this.createString(companyInfo.bank);
  }
  private _createBusinessName(companyInfo: any): string {
    return this.createString(companyInfo.business_name);
  }
  private _createConservatory(companyInfo: any): string {
    return this.createString(companyInfo.conservatory);
  }
  private _createCurrencyCode(companyInfo: any): string {
    return this.createString(companyInfo.currency_code);
  }
  // private _createCreatedDate(warehouseInfo: any): string {
  //   return this.createDateTime(warehouseInfo.created_date);
  // }
  // private _createDeletedDate(warehouseInfo: any): string {
  //   return this.createDateTime(warehouseInfo.deleted_date);
    
  // }
  private _createCreatedDate(companyInfo: any) {
    return this._createDateTimeFromTimestamp(companyInfo.created_date);
  } 
  private _createModifiedDate(companyInfo: any) {
    return this._createDateTimeFromTimestamp(companyInfo.modified_date);
  }
  private _createDeletedDate(companyInfo: any) {
    return this._createDateTimeFromTimestamp(companyInfo.deleted_date);
  }   
  private _createEacCode(companyInfo: any): string {
    return this.createString(companyInfo.eac_code);
  }
  private _createEmail(companyInfo: any): string {
    return this.createString(companyInfo.email);
  }
  private _createObs(companyInfo: any): string {
    return this.createString(companyInfo.obs);
  }
  private _createIban(companyInfo: any): string {
    return this.createString(companyInfo.iban);
  }
  private _createCompanyId(companyInfo: any): string {
    return this.createString(companyInfo.id);
  }
  private _createImageUpdate(companyInfo: any): number {
    return this._createNumber(companyInfo.image_update);
  }
  private _createIsGeneratingSaft(companyInfo: any): boolean {
    return companyInfo.is_generating_saft != 0;
  }
  private _createLogo(companyInfo: any): string {
    if (companyInfo.logo) {
      return this.api._url + '/company_logo?company_id=' + companyInfo.id;
    }
    return '';
  }
  private _createName(companyInfo: any): string {
    return this.createString(companyInfo.name);
  }
  private _createNif(companyInfo: any): string {
    return this.createString(companyInfo.nif);
  }
  private _createPermanentCertificate(companyInfo: any): string {
    return this.createString(companyInfo.permanent_certificate);
  }
  private _createShareCapital(companyInfo: any): number {
    if (!companyInfo.share_capital) {
      return 0;
    }
    return parseFloat(companyInfo.share_capital);
  }
  private _createShippingAddress(companyInfo: any): Address {
    return this.addressService.createAddressFromRequest(
      companyInfo.shipping_address
    );
  }
  private _createSwift(companyInfo: any): string {
    return this.createString(companyInfo.swift);
  }
  private _createTelephone(companyInfo: any): string{
    return this.createString(companyInfo.telephone);
  }
  private _createWebsite(companyInfo: any): string{
    return this.createString(companyInfo.website)
  }
  private _createManager(companyInfo: any): Manager{
    return this.managerService.createManagerFromRequest(companyInfo.manager);
  }
  private _createBillingMethod(companyInfo: any): string{
    return this.createString(companyInfo.billing_method);
  }
  private _createShippingMethod(companyInfo: any): string{
    return this.createString(companyInfo.shipping_method);
  }
  private _createLastDocumentDate(companyInfo: any) {
    return this._createDateTimeFromTimestamp(companyInfo.last_document_date);
  }
  private _createShowTelephone(companyInfo: any): boolean{
    return this.createBoolean(companyInfo.hide_telephone);
  }
  private _createShowEmail(companyInfo: any): boolean{
    return this.createBoolean(companyInfo.hide_email);
  }
  private _createShowIban(companyInfo: any): boolean{
    return this.createBoolean(companyInfo.hide_iban);
  }
  private _createDemo(companyInfo: any): boolean{
    return this.createBoolean(companyInfo.demo);
  }
  public createCompanyFromRequest(companyInfo: any): Company {
    if (!companyInfo) {
      return this.newEmptyCompany();
    }

    let company: Company = {
      address: this._createAddress(companyInfo),
      bank: this._createBank(companyInfo),
      businessName: this._createBusinessName(companyInfo),
      conservatory: this._createConservatory(companyInfo),
      createdDate: this._createCreatedDate(companyInfo),
      modifiedDate: this._createModifiedDate(companyInfo),
      currencyCode: this._createCurrencyCode(companyInfo),
      eacCode: this._createEacCode(companyInfo),
      email: this._createEmail(companyInfo),
      obs: this._createObs(companyInfo),
      iban: this._createIban(companyInfo),
      companyId: this._createCompanyId(companyInfo),
      imageUpdate: this._createImageUpdate(companyInfo),
      isGeneratingSaft: this._createIsGeneratingSaft(companyInfo),
      logo: this._createLogo(companyInfo),
      name: this._createName(companyInfo),
      nif: this._createNif(companyInfo),
      permanentCertificate: this._createPermanentCertificate(companyInfo),
      shareCapital: this._createShareCapital(companyInfo),
      shippingAddress: this._createShippingAddress(companyInfo),
      swift: this._createSwift(companyInfo),
      telephone: this._createTelephone(companyInfo),
      website: this._createWebsite(companyInfo),
      manager:this._createManager(companyInfo),
      billing_method:this._createBillingMethod(companyInfo),
      shipping_method:this._createShippingMethod(companyInfo),
      showTelephone:this._createShowTelephone(companyInfo),
      showEmail:this._createShowEmail(companyInfo),
      showIban:this._createShowIban(companyInfo),
      demo:this._createDemo(companyInfo),

    };

    if (companyInfo.deleted_date)
        company['deletedDate'] = this._createDeletedDate(companyInfo)
    if (companyInfo.last_document_date)
      company['lastDocumentDate'] = this._createLastDocumentDate(companyInfo)
    return company;
  }

  delete(company: Company, obs: string) {
    const formData = new FormData();
    formData.append('company_id', company.companyId);
    formData.append('obs', obs);

    const observable = this.api.delete(this.url, formData, true);
    observable.subscribe((_) => this.getAll());
    return observable;
  }

  get(company: Company) {
    const formData = new FormData();
    formData.append('company_id', company.companyId);

    return this.api.get(
      this.url + `?company_id=${company.companyId}`,
      formData,
      true
    );
  }

  getAll() {
    const formData = new FormData();
    return this.api.getAll('/companies', formData, true).pipe(
      map((res: any) => {
        res = res[baseResponseObject]
          // .filter((d) => d.deleted_date == null)
          .map((d: any) => this.createCompanyFromRequest(d))
          .sort((c1: Company, c2: Company) => (c1.name < c2.name ? -1 : 1));
        
        this.selectLastCompany(res);
        this.companies.next(res);
        return res;
      })
    );
  }

  selectLastCompany(res) {
    const selectedCompanyId = localStorage.getItem("selectedCompanyId");
    if(!selectedCompanyId) return this.selectedCompany.next(res[0]);
    for (let company of res) {
      if (company.companyId == selectedCompanyId) {
        this.selectedCompany.next(company);
        return;
      }
    }

    this.selectedCompany.next(res[0]);
  }

  post(company: Company) {
    const formData = this.createCompanyFormDate(company);
    return this.api.post(this.url, formData, true, true);
  }

  put(company: Company) {
    console.log(company);
    const formData = this.createCompanyFormDate(company);
    formData.append('company_id', company.companyId);

    return this.api.put(this.url, formData, true, true);
  }

  createCompanyFormDate(company: Company) {
    const formData = new FormData();
    formData.append('nif', company.nif);
    formData.append('name', company.name);
    formData.append('eac_code', company.eacCode);
    formData.append('address_id', company.address.id);
    formData.append('manager_id', company.manager.id);
    formData.append('currency_code', company.currencyCode);
    formData.append('business_name', company.businessName);
    if (company.conservatory) {
      formData.append('conservatory', company.conservatory);
    }
    if (company.permanentCertificate) {
      formData.append('permanent_certificate', company.permanentCertificate);
    }
    if (
      company.shippingAddress &&
      company.shippingAddress.id &&
      company.shippingAddress.id != ''
    ) {
      formData.append('shipping_address_id', company.shippingAddress.id);
    }
    if (company.bank) {
      formData.append('bank', company.bank);
    }
    if (company.iban) {
      formData.append('iban', company.iban);
    }
    if (company.swift) {
      formData.append('swift', company.swift);
    }

    formData.append('email', company.email);
    formData.append('telephone', company.telephone);
    formData.append('share_capital', company.shareCapital + '');
    formData.append('obs',company.obs);
    formData.append('billing_method',company.billing_method);
    formData.append('shipping_method',company.shipping_method);
    formData.append('hide_telephone', company.showTelephone ? 'False' : 'True');
    formData.append('hide_email', company.showEmail ? 'False' : 'True');
    formData.append('hide_iban', company.showIban ? 'False' : 'True');
    return formData;
  }
  
  getCompanyCurrencyType() {
    const url = '/company/currency_type';
    const formData = new FormData();
    this.api
      .getAll(url, formData, false)
      // .pipe(map((resp: any) => ({ value: resp, label: resp }) ))
      .subscribe((res: any) => this.currencyTypes.next(res[baseResponseObject]));
  }

  getCompanyBusinessType() {
    const formData = new FormData();
    const req = this.api
      .getAll('/company/business_type', formData, false)
      .subscribe((res: any) => {
        this.businessTypes.next(res[baseResponseObject]);
      });
  }

  selectCompanyFunction(company): any {
    if (company.companyId !== '') {
      return this.put(company);
    }
    return this.post(company);
  }

  disabledSelectCompany(): boolean {
    return (
      this.selectedCompany.getValue() &&
      this.selectedCompany.getValue().companyId != ''
    );
  }

  generateSaft(company, start_ts, end_ts) {
    const url = '/company/generate_saft';
    const formData = new FormData();

    formData.append('company_id', company.companyId);
    formData.append('start_ts', start_ts);
    formData.append('end_ts', end_ts);

    return this.api.postXml(url, formData, true);
  }

  importFile(data: any) {
    const url = '/company/import_saft';
    const formData = new FormData();

    formData.append('company_id', this.selectedCompany.getValue().companyId);
    formData.append('data', data);

    return this.api.post(url, formData, true);
  }

  cashFlow(
    company: Company,
    startTs: number,
    endTs: number,
    customer?: Customer
  ) {
    const url = '/company/cash_flow';
    const formData = new FormData();

    formData.append('company_id', company.companyId);
    formData.append('start_ts', startTs + '');
    formData.append('end_ts', endTs + '');
    if (customer && customer.id) {
      formData.append('customer_id', customer.id);
    }

    return this.api.post(url, formData, true, false);
  }

  companyCashFlowFiltered(
    companyId: string,
    minLimit?,
    maxLimit?,
    startTs?,
    endTs?,
    orderedList?,
    textFilter?,
    customer?: Customer,
    filter_type?
  ) {
    const url = '/company/cash_flow_filtered';
    const formData = new FormData();
  
    formData.append('company_id', companyId);
  
    if (startTs) {
      formData.append('start_ts', startTs + '');
    }
  
    if (endTs) {
      formData.append('end_ts', endTs + '');
    }
  
    if (customer && customer.id != '') {
      formData.append('customer_id', customer.id);
    }
  
    // Return the observable directly here
    return this.api
      .getFiltered(
        url,
        formData,
        minLimit,
        maxLimit,
        orderedList,
        textFilter,
        filter_type
      )
      .pipe(
        map((res: any) => {
          return {
            documents_number: res[baseResponseObject].documents_number,
            documents: res[baseResponseObject].documents,
            payment_value: res[baseResponseObject].payment_value,
            invoice_value: res[baseResponseObject].invoice_value,
            pending_value: res[baseResponseObject].pending_value
          };
        })
      );
  }

  companyDownloadSaft(companyId: string) {
    const url = '/company/download_saft';
    const formData = new FormData();

    formData.append('company_id', companyId);

    return this.api.postXml(url, formData, true);
  }

  validateCompany(company: Company): boolean {
    var check: boolean = true;

    check &&= this.validateNif(company);
    check &&= this.validateName(company);
    if (company.nif.startsWith('5')){
      check &&= this.validateEacCode(company);
      check &&= this.validatePermanentCertificate(company);
      check &&= this.validateConservatory(company);
      check &&= this.validateSharedCapital(company);
    }
    check &&= this.validateEmail(company);
    check &&= this.validateTelephone(company);
    check &&= this.validateBillingMethod(company);
    check &&= this.validateShippingMethod(company);

    return check;
  }

  updateImage(formData) {
    return this.api.put(this.url, formData, true, true);
  }

  validateNif(company: Company): boolean {
    // if (parseInt(company.nif[0]) >= 5 ) {
    //   return false;
    // }

    return this.utilityService.validateString(company.nif, 9, 9, [], true);
  }
  validateName(company: Company): boolean {
    return this.utilityService.validateString(
      company.name.replace(/\s/g, ''),
      1,
      100,
      [],
      true
    );
  }
  validateEacCode(company: Company): boolean {
    if (company.eacCode != 'Outro'){
      return this.utilityService.validateString(company.eacCode, 1, 60, [], true);
    }
    else{
      return this.utilityService.validateString(company.otherEac, 1, 60, [], true);

    }
  }
  validatePermanentCertificate(company: Company): boolean {
    return this.utilityService.validateString(
      company.permanentCertificate,
      14,
      14,
      [],
      true
    );
  }
  validateConservatory(company: Company): boolean {
    return this.utilityService.validateString(
      company.conservatory,
      1,
      200,
      [],
      true
    );
  }
  validateSharedCapital(company: Company): boolean {
    return this.utilityService.validateString(
      company.shareCapital + '',
      1,
      150,
      [],
      true
    );
  }
  validateEmail(company: Company): boolean {
    return this.utilityService.validateString(
      company.email,
      1,
      60,
      ['.', '@'],
      true
    );
  }
  validateTelephone(company: Company): boolean {
    return this.utilityService.validateString(company.telephone, 9, 9, [], true);
  }
  validateBanc(company: Company): boolean {
    return this.utilityService.validateString(company.bank, 1, 60, [], false);
  }
  validateIbam(company: Company): boolean {
    return this.utilityService.validateString(company.iban, 1, 60, [], false);
  }
  validateSwift(company: Company): boolean {
    return this.utilityService.validateString(company.swift, 1, 60, [], false);
  }

  validateBillingMethod(company: Company): boolean {
    return this.utilityService.validateString(company.billing_method, 1, 200, [], true);
  }

  validateShippingMethod(company: Company): boolean {
    return this.utilityService.validateString(company.shipping_method, 1, 200, [], true);
  }

}
