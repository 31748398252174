import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class BaseControlsService {

  isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  recoveryCreateModal: Subject<any> = new Subject<any>();
  private recoveryObj: any = undefined;
  // isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(
    private utilityService: UtilityService
  ) { }

  setRecoveryObj(obj: any){
    this.recoveryObj = this.utilityService.deepCopy(obj);
  }

  reopenCreateModal(){
    this.recoveryCreateModal.next(this.utilityService.deepCopy(this.recoveryObj));
  }

}
