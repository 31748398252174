import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-breadcrumb',
  templateUrl: './page-breadcrumb.component.html',
  styleUrls: ['./page-breadcrumb.component.scss']
})
export class PageBreadcrumbComponent implements OnInit {

  breadcrumb: string[] = []

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.breadcrumb = this.router.url.split('/').slice(1)
  }

}
