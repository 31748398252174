import { UserService } from "./../../../core/services/user.service";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
  Renderer2,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Router } from "@angular/router";
import { User } from "src/app/core/model/User";
import { ApiService } from "src/app/core/services/api.service";
import { AuthService } from "src/app/core/services/auth.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  user: User;
  languages: { language: string; icon: string; title: string }[] = [];
  selectedLanguage: { language: string; icon: string; title: string };

  // DEBUG MODE
  debugMode: boolean = true;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private userService: UserService,
    private api: ApiService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    const debugMode = localStorage.getItem("debugMode");
    if (debugMode) {
      this.debugMode = debugMode == "true";
    }

    this.languages.push({
      language: "pt",
      icon: "flag-icon flag-icon-pt",
      title: "Português",
    });

    this.languages.push({
      language: "en",
      icon: "flag-icon flag-icon-us",
      title: "English",
    });

    this.selectedLanguage = window.location.href.includes("/pt/")
      ? this.languages[0]
      : this.languages[1];

    this.userService.language.next(this.selectedLanguage.language);

    this.userService.user.subscribe((user) => {
      this.user = user;
    });
  }

  setLanguage(language: any) {
    // let url = "https://www.tlbilling.pt/";
    let url = "https://tlbweb.geolink.pt/";
    switch (language.language) {
      case "pt":
        url += "pt/";
        break;
      default:
        url += "en/";
        break;
    }
    // console.log(language.language, language.language=='pt', url)
    window.location.href = url;

    // this.selectedLanguage = language;
    // this.userService.language.next(language.language);
    // window.location.hash = 'www.google.com'
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle("sidebar-open");
  }

  /**
   * Logout
   */
  onLogout(e) {
    // e.preventDefault();
    // localStorage.removeItem("authToken");
    // this.api.removeAuthToken();

    // if (!localStorage.getItem("authToken")) {
    //   this.router.navigate(["/auth/login"]);
    // }
    this.authService.onLogout(e);
  }

  showProfile() {
    console.log(this.user);
  }

  debugModeEvent() {
    localStorage.setItem("debugMode", this.debugMode + "");
    this.userService.debugMode.next(this.debugMode);
  }
}
