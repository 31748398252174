import { Component, OnDestroy, OnInit } from "@angular/core";
import { combineLatest } from "rxjs";
import { filter } from "rxjs/operators";
import { Company } from "src/app/core/model/Company";
import { CompanyService } from "src/app/core/services/company.service";
import { UserService } from "src/app/core/services/user.service";

@Component({
  selector: "app-company-management",
  templateUrl: "./company-management.component.html",
  styleUrls: ["./company-management.component.scss"],
})
export class CompanyManagementComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  subFilteredRequest: any = null;
  subCombineLatest: any = null;
  companies: Company[] = [];
  numTotalData: number = 0;

  constructor(
    private userService: UserService,
    private companyService: CompanyService
  ) {}

  ngOnInit(): void {
    this.subCombineLatest = combineLatest([this.userService.user])
      .pipe(filter(([user]) => user.id != ""))
      .subscribe((_) => this.getFiltered(0, 20));
  }

  ngOnDestroy(): void {
    this.unsubscribeFilteredRequest();
  }

  updateTableFunc(data) {
    const min = data.page * data.numPerPage;
    const max = (data.page + 1) * data.numPerPage;
    const orderedList = data.ordered_list;
    const textFilter = data.text_filter;
    const reset = data.reset
    this.getFiltered(min, max,reset, orderedList, textFilter);
  }

  unsubscribeFilteredRequest() {
    if (this.subCombineLatest) {
      this.subCombineLatest.unsubscribe();
      this.subCombineLatest = null;
    }
    if (this.subFilteredRequest) {
      this.subFilteredRequest.unsubscribe();
      this.subFilteredRequest = null;
    }
  }

  getFiltered(min, max,reset=false,orderedList?, textFilter?) {
    
    this.unsubscribeFilteredRequest();
    if (reset==true) {
      this.loading = true;
      min=0
      max=20
      this.companies = [];
    }

    this.subFilteredRequest = this.companyService
      .getFiltered(min, max, orderedList, textFilter)
      .subscribe(({ companies_number, companies }) => {
        this.loading = false;
        this.companies = this.companies.concat(companies);
        this.numTotalData = companies_number;
      });
  }
}
