import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule,ReactiveFormsModule } from "@angular/forms";

import { BaseComponent } from "./base/base.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { FooterComponent } from "./footer/footer.component";
import { ChangePasswordPopupComponent } from "./change-password-popup/change-password-popup.component";


import { ContentAnimateDirective } from "../../core/content-animate/content-animate.directive";

import {
  NgbDropdownModule,
  NgbCollapseModule,
} from "@ng-bootstrap/ng-bootstrap";

import { FeahterIconModule } from "../../core/feather-icon/feather-icon.module";

import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { PageBreadcrumbComponent } from "./page-breadcrumb/page-breadcrumb.component";

// SweetAlert2Module
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { ClientFeedbackComponent } from "./client-feedback/client-feedback.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { ErrorModule } from "../error/error.module";
import { AngularCropperjsModule } from "angular-cropperjs";
import { UpdateCompanyImageComponent } from "../pages/admin/company-management/update-company-image/update-company-image.component";
import { SelectCompanyComponent } from "./select-company/select-company.component";
import { CompanyManagementModule } from "../pages/admin/company-management/company-management.module";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  declarations: [
    BaseComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    ContentAnimateDirective,
    PageBreadcrumbComponent,
    ClientFeedbackComponent,
    SelectCompanyComponent,
    ChangePasswordPopupComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbDropdownModule,
    NgbCollapseModule,
    PerfectScrollbarModule,
    FeahterIconModule,
    SweetAlert2Module.forRoot(),
    SweetAlert2Module,
    SweetAlert2Module.forChild({
      /* options */
    }),
    NgbModule,
    AngularCropperjsModule,
    CompanyManagementModule,
    ReactiveFormsModule,

  ],
  exports: [PageBreadcrumbComponent],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
})
export class LayoutModule {}
