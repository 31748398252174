import { Injectable } from '@angular/core';
import { DocumentProduct } from '../model/DocumentProduct';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor() {}

  round(val: number, numRound?): number {
    val = parseFloat(val + '');
    try {
      numRound = numRound !== undefined ? numRound : 4;
      return parseFloat(parseFloat(val + '').toFixed(numRound));
    } catch (e) {
      console.log(val);
    }
  }

  sum(vals: number[]): number {
    return vals
      .map((d) => (d ? d : 0))
      .reduce((a, b) => this.round(a + this.round(b)), 0);
  }

  generateRandomName(): string {
    const nameList = [
      'Santiago',
      'Maria',
      'Francisco',
      'Leonor',
      'João',
      'Matilde',
      'Afonso',
      'Beatriz',
      'Rodrigo',
      'Carolina',
      'Martim',
      'Mariana',
      'Tomás',
      'Ana',
      'Duarte',
      'Sofia',
      'Miguel',
      'Francisca',
      'Gabriel',
      'Inês',
    ];

    return nameList[parseInt(nameList.length * Math.random() + '')];
  }

  generateRandomSurnameName(): string {
    const nameList = [
      'Silva',
      'Santos',
      'Ferreira',
      'Pereira',
      'Oliveira',
      'Costa',
      'Rodrigues',
      'Martins',
      'Jesus',
      'Sousa',
      'Fernandes',
      'Gonçalves',
      'Gomes',
      'Lopes',
      'Marques',
      'Alves',
      'Almeida',
      'Ribeiro',
      'Pinto',
      'Carvalho',
      'Teixeira',
      'Moreira',
      'Correia',
      'Mendes',
      'Nunes',
    ];

    return nameList[parseInt(nameList.length * Math.random() + '')];
  }

  deepCopy(obj: any): any {
    if (obj) {
      return JSON.parse(JSON.stringify(obj));
    }
    return undefined;
  }

  validateString(
    str = '',
    minSize = -1,
    maxSize = -1,
    spCharacters = [],
    mandatory: boolean = false
  ): boolean {
    if (mandatory) {
      if (!str) {
        return false;
      }
    } else {
      if (!str) {
        return true;
      }
    }

    return (
      (str.length >= minSize || minSize < 0) &&
      (str.length <= maxSize || maxSize < 0) &&
      spCharacters.map((s) => str.includes(s)).reduce((a, b) => a && b, true)
    );
  }

  getTsFromDate(date: { year: number; month: number; day: number }): number {
    if (!date) {
      return undefined;
    }
    const { year, month, day } = date;
    return parseInt(new Date(year, month - 1, day).getTime() / 1000 + '');
  }

  getNowTs(): number {
    return parseInt(+new Date() / 1000 + '');
  }

  getDateFromTs(ts: number): { year: number; month: number; day: number } {
    const date = new Date(ts * 1000);
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }

  compareObjsPrams(obj1: any, obj2: any, params: string[]): boolean {
    if (!obj1 || !obj2) {
      return false;
    }

    for (let key of params) {
      if (obj1[key] != obj2[key]) return false;
    }

    return true;
  }

  unique(array: any[]) {
    return Object.values(
      array.reduce((a, b) => {
        a[b] = b;
        return a;
      }, {})
    );
  }

  zip<T, U>(arr1: T[], arr2: U[]): [T, U][] {
    if (!arr1 || !arr2 || arr1.length != arr2.length) {
      throw 'Invalid Arrays!';
    }

    return arr1.map((_, i) => [arr1[i], arr2[i]]);
  }

  nifValidate(nif: string) {}

  checkMinGrossValueValue(product: DocumentProduct): void {
    product.value = parseFloat(product.value + '');
    if (product.value == 0) return;

    const numRound =4; ;
    const minNetValue = 1 / 10 ** numRound;
    const taxBase = 1 + product.product.tax.percentage / 100;

    const netValue = product.value / taxBase;

    if (minNetValue > netValue) {
      product.value = minNetValue * taxBase;
    }

    product.value = this.round(product.value);
  }
}
