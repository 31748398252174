import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HighlightSpanKind } from 'typescript';
import { CountryCode } from '../model/CountryCode';

@Injectable({
  providedIn: 'root'
})
export class CountryCodeService {
  countryCodes: BehaviorSubject<CountryCode[]> = new BehaviorSubject<CountryCode[]>([])

  constructor() {
    this.getAll()
   }

   createEmpty(): CountryCode{
    return { name: '-', code: '-1' }
   }

   getFromRequest(country: string){
     switch(country){
      case 'PT': return this.countryCodes.getValue()[0]
      case 'ES': return this.countryCodes.getValue()[1]
      default: return this.countryCodes.getValue()[0]
     }
   }

  getAll(){
    this.countryCodes.next([{name: 'Portugal', code: 'PT'}, {name: 'Espanha', code: 'ES'}])
  }

  compare(c1: CountryCode, c2: CountryCode): boolean{
    if(!c2){
      return false;
    }
    return c1.name == c2.name && c1.code == c2.code;
  }


}
