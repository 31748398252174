import {
  HttpClient,
  HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { baseResponseObject } from "../configs/request.config";
import { ApiService } from "./api.service";
import { BaseControlsService } from "./base-controls.service";
import { CompanyService } from "./company.service";
import { UserService } from "./user.service";
import { Observable } from 'rxjs';
import { of } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private _url: string = ``;
  private _authToken = "";
  returnUrl: any;

  constructor(
    private http: HttpClient,
    private api: ApiService,
    private baseControlsService: BaseControlsService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private companyService: CompanyService
  ) {
    // Set API URL
    this._url = this.api._url;
    
    // Get return URL from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";

    // Initialize user info
    // this.userInfo();

    // Set logout handler
    this.api.onLogout = () => {
      this.onLogout(null);
    };
  }

  onLogout(e) {
    // e.preventDefault();
    localStorage.removeItem("authToken");
    this.api.removeAuthToken();

    if (!localStorage.getItem("authToken")) {
      this.router.navigate(["/auth/login"]);
    }
  }

  public getAuthToken() {
    return this._authToken;
  }

  public getUrl() {
    return this._url;
  }

  private handleError(error: HttpErrorResponse) {
    // if (error.status == 401) {
    //   this.onLogout(null);
    //   return;
    // }

    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    }
    return throwError("Something bad happened; please try again later2.");
  }

  async login(email: string, password: string, rememberMe?: boolean) {
    const url = "/user/login";
    const formData = new FormData();

    formData.append("email", email);
    formData.append("password", password);

    return this.api
      .post(url, formData, false)
      .subscribe(({ msg }: any) => {
        this._authToken = msg.auth_token;
        localStorage.setItem("authToken", this._authToken);
        this.userInfo();
        this.baseControlsService.isLoading.next(false);
        if (this.returnUrl == "/") {
          this.router.navigate(["/"]);
        } else {
          this.router.navigate(["/"]);
        }
      });
  }

  register(
    first_name: string,
    last_name: string,
    email: string,
    contact: string,
    password: string
  ) {
    const url = this._url + "/user";
    const formData = new FormData();

    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("contact", contact);
    formData.append("email", email);
    formData.append("password", password);

    return this.api
      .post(url, formData, false)
      .pipe(catchError(this.handleError));
  }

  async userInfo() {
    const url = this._url + "/user";
    const authToken = localStorage.getItem("authToken");
    this.companyService.getAll().subscribe();
    
    const httpOptions = {
      headers: { "X-Forwarded-For": authToken },
    };

    return this.http
      .get<any>(url, httpOptions)
      .pipe(catchError((error) => this.handleError(error)))
      .subscribe((d) => this.userService.setUserFromRequest(d[baseResponseObject]));
  }

  recoverPassword(email: string) {
    const url = "/user/recover_password";
    const formData = new FormData();

    formData.append("email", email);

    return this.api.post(url, formData, false);

  }

  recoverPasswordReply(token: string) {
    const url = "/user/recover_password";
    const formData = new FormData();
    formData.append("token", token);
  
    return this.api.get(url + '?token=' + token, formData, false)
  }


}
