import { Injectable } from '@angular/core';
import { BehaviorSubject , forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserTypeOption } from '../model/UserTypeOption';
import { User } from './../model/User';
import { Company } from './../model/Company';
import { Warehouse } from './../model/Warehouse';
import { ApiService } from './api.service';
import { CompanyService } from './company.service';
import { UtilityService } from './utility.service';
import { WarehouseService } from './warehouse.service';
import { baseResponseObject } from '../configs/request.config';

@Injectable({
  providedIn: 'root'
})
export class UserAdminService {
  private url = '/user';

  users: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  userTypes: BehaviorSubject<UserTypeOption[]> = new BehaviorSubject<UserTypeOption[]>([]);

  constructor(
    private api: ApiService,
    private companyService: CompanyService,
    private warehouseService: WarehouseService,
    private utilityService: UtilityService
  ) {
    this.getUserType();
  }

  public getFiltered(companyId: string, minLimit, maxLimit, orderedList?, textFilter?, filter_type?) {
    const url = '/users_filtered';
    const formData = new FormData();

    formData.append('company_id', companyId);
    return this.api.getFiltered(url, formData, minLimit, maxLimit, orderedList, textFilter, filter_type)
      .pipe(map((resp: any) => {
        return { users_number: resp[baseResponseObject].users_number, users: resp[baseResponseObject].users.map(c => this.createUserFromRequest(c)) };
      }));
  }

  public newEmptyUser(): User {
    return {
      activated: false, contact: '',
      created_date: 0, email: '', first_name: '',
      id: '', last_name: '', type: 3,name: '',
      permissions: {
        companies: [],
        warehouses: [],
      }
    }
  }

  public compareUser(u1: User, u2: User): boolean {
    if (!u2) {
      return false
    }
    const params = ['activated', 'contact', 'created_date', 'email', 'first_name', 'id', 'last_name'];
    return this.utilityService.compareObjsPrams(u1, u2, params)
  }

  public createUserFromRequest(content: any): User {
    let user: User = {
      activated: content.activated,
      contact: content.contact,
      created_date: content.created_date,
      email: content.email ? content.email : '',
      first_name: content.first_name,
      id: content.id,
      last_name: content.last_name,
      type: content.type,
      name:content.name
    }

    if (content.permissions) {
      
      user['permissions'] = {
        companies: content.permissions.companies.map(c => ({
          company: this.companyService.createCompanyFromRequest(c.company),
          added_date: c.added_date,
          removed_date:c.removed_date
        })),
        warehouses: content.permissions.warehouses.map(w => ({
          warehouse: this.warehouseService.createWarehouseFromRequest(w.warehouse),
          added_date: w.added_date,
          removed_date:w.removed_date
        }))
      }
    }

    return user;
  }



  private _createDateTimeFromTimestamp(timestamp: number): Date {
    const minLength = 12;
    if (!timestamp) return new Date(0);
    if ((timestamp + '').length < minLength) return new Date(timestamp * 1000);
    return new Date(timestamp);
  }

  delete(user: User,companyId: string,warehouseId: string=null) {
    const formData = new FormData();
    formData.append('user_id', user.id);
    formData.append('company_id', companyId);
    formData.append('warehouse_id', warehouseId);
    return this.api.delete('/user/delete_permission', formData, true);
  }

  get(user: User) {
    const formData = new FormData();
    formData.append('id', user.id);

    return this.api.get(this.url, formData, true);
  }

  getAll() {
    const formData = new FormData();
    const req = this.api.getAll('/users', formData, true);
    req.subscribe((resp: any) => {
      this.users.next(
        resp[baseResponseObject]
          .map((d: any) => this.createUserFromRequest(d))
          .sort((a: User, b: User) =>
            a.first_name + a.last_name < b.first_name + b.last_name ? -1 : 1
          )
      );
    })

    return req;
  }

  
  post(user: User) {
    const formData = this.createRequestUserFormData(user);
    formData.append('type', user.type+'');
    return this.api.post(this.url, formData, true, false);
  }

  post_create_user_company(user: any) {
    const formData = new FormData();
    formData.append('first_name', user.first_name);
    formData.append('last_name', user.last_name);
    formData.append('email', user.email);
    formData.append('contact', user.contact);
    console.log(user.permissions)
    const permissions = {
      companies: user.permissions.companies.map(c => c.id),
      warehouses: user.permissions.warehouses.map(w => w.warehouseId)
    };
    formData.append('permissions', JSON.stringify(permissions));
    formData.append('type', user.type+'');
    return this.api.post(this.url, formData, true, false);

  }

  put(user: User) {
    const formData = this.createRequestFormData(user);
    console.log(formData)
    const formDataa = new FormData();
    return this.api.put(this.url,formDataa , true, true);
  }

  put_warehouse(user: User,warehouses: any[], companyId: string): Observable<any> {
    console.log("put_warehouse", warehouses)
    const warehouses_id= warehouses.filter(w => w).filter(w => w.permission_removed_date===undefined).map(w => w.warehouseId);

    console.log("put_warehouse warehouses_id", warehouses_id)
    const formData_permissions = new FormData();
    formData_permissions.append('company_id', companyId);
    formData_permissions.append('warehouses', JSON.stringify(warehouses_id));
    formData_permissions.append('user_id', user.id);

    return this.api.post('/user/update_warehouse_permission', formData_permissions, true, false)

  }
  
  put_type_driver(user: User) {
    const formData = this.createRequestFormData(user);
    formData.append('user_id', user.id);
    formData.append('type', 4+'');

    return this.api.put(this.url, formData, true, true);
  }
  put_type_superdriver(user: User) {
    const formData = this.createRequestFormData(user);
    formData.append('user_id', user.id);
    formData.append('type', 3+'');

    return this.api.put(this.url, formData, true, true);
    
  }
  put_password(user_id: string, newPassword: string) {
    const formData = new FormData();
    formData.append('user_id', user_id);
    formData.append('password', newPassword);
    return this.api.put(this.url, formData, true, true);
  }

  put_password_exp(user_id: string, password_exp: string) {
    const formData = new FormData();
    formData.append('user_id', user_id);
    formData.append('password_exp', password_exp);

    return this.api.put(this.url, formData, true, true);
  }

  post_check_password(user_id: string, password: string) {
    const formData = new FormData();
    formData.append('user_id', user_id);
    formData.append('password', password);
    return this.api.post('/user/check_password', formData, true);
  }
  
  post_add_permissions(company_id: string, email: string = '', warehouse_id?: string,user_id?: string): Observable<any> {
    const formData = new FormData();
    if (email) {
      formData.append('email', email);
    }
    
    formData.append('company_id', company_id);
    console.log("warehouse_id", warehouse_id)
    if (warehouse_id) {
      formData.append('warehouse_id', warehouse_id);
    }
    if (user_id) {
      formData.append('user_id', user_id);
    }
  
    return this.api.post('/user/add_permission', formData, true);
  }
  

  put_permission(user_id: string, company_id: string) {
    const formData = new FormData();
    formData.append('user_id', user_id);
    formData.append('company_id', company_id);  

    return this.api.put('/user/update_permission', formData, true);
  }


  createRequestFormData(user: User) {
    const formData = new FormData();

    formData.append('user_id', user.id);
    formData.append('first_name', user.first_name);
    formData.append('last_name', user.last_name);
    formData.append('contact', user.contact);
    if (user.email){
      formData.append('email', user.email);
    }

    if (user.type !== 0) {
      
    
      // Append permissions to formData if user type is not 0
      const permissions = {
        companies: user.permissions.companies.map(c => c.company.companyId),
        warehouses: user.permissions.warehouses.map(w => w.warehouse.warehouseId)       
      };
      formData.append('permissions', JSON.stringify(permissions));
    }

    return formData;
  }


  createRequestUserFormData(user: User) {
    const formData = new FormData();

    formData.append('user_id', user.id);
    formData.append('first_name', user.first_name);
    formData.append('last_name', user.last_name);
    formData.append('contact', user.contact);
    if (user.email){
      formData.append('email', user.email);
    }

    if (user.type !== 0) {
      
    
      // Append permissions to formData if user type is not 0
      const permissions = {
        companies: user.permissions.companies.map(c => c.company.companyId),
        warehouses: user.permissions.warehouses.map(warehouse => {
          return warehouse['warehouseId']; 
        })      
      };
      formData.append('permissions', JSON.stringify(permissions));
    }

    return formData;
  }

  getUserType() {
    const url = '/user/user_type';
    const formData = new FormData();

    this.api
      .get(url, formData, true, false)
      .subscribe((resp: any) => this.userTypes.next(resp[baseResponseObject]));
  }
}
