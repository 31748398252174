import { BaseControlsService } from './../../../core/services/base-controls.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { CompanyService } from "src/app/core/services/company.service";
import { Company } from "src/app/core/model/Company";

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {

  isLoading: boolean;
  selectedCompany: Company;

  constructor(private router: Router, private baseControlsService: BaseControlsService,
    private companyService: CompanyService,
  ) {

    // Spinner for lazyload modules
    router.events.forEach((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.baseControlsService.isLoading.next(true);
      } else if (event instanceof RouteConfigLoadEnd) {
        this.baseControlsService.isLoading.next(false);
      }
    });
  }

  ngOnInit(): void {
    this.baseControlsService.isLoading.subscribe(isLoading => this.isLoading = isLoading)
    this.companyService.selectedCompany.subscribe(
      (c) => (this.selectedCompany = c)
    );

  }

}
