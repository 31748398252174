import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import {
  NgbCollapseModule,
  NgbDropdownModule,
  NgbModule,
} from "@ng-bootstrap/ng-bootstrap";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { AngularCropperjsModule } from "angular-cropperjs";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { FeahterIconModule } from "src/app/core/feather-icon/feather-icon.module";
import { CreateElementsModule } from "src/app/views/create-elements/create-elements.module";
import { CompanyManagementComponent } from "./company-management.component";
import { UpdateCompanyImageComponent } from "./update-company-image/update-company-image.component";
import {CompanyLogoComponent} from './company-logo/company-logo.component';
const components = [UpdateCompanyImageComponent,CompanyLogoComponent];

const routes: Routes = [
  {
    path: "",
    component: CompanyManagementComponent,
  },
];

@NgModule({
  declarations: [components],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    NgbDropdownModule,
    NgbCollapseModule,
    PerfectScrollbarModule,
    FeahterIconModule,
    SweetAlert2Module.forRoot(),
    SweetAlert2Module,
    SweetAlert2Module.forChild({
      /* options */
    }),
    NgbModule,
    AngularCropperjsModule,
    // ErrorModule,
    // DatabaseModule,
    // DropzoneModule, // Ngx-dropzone-wrapper
    // CompanyInfoTableModule,

    // ModulesModule,
    // InputsModule,
    // ModalsModule,
    // CreateElementsModule,
    // InfoTablesModule,
    // SelectElementTableModule,
    // CompanyInfoTableModule,

    // ModulesModule,
    // InputsModule,
    // ErrorModule,
    // ModalsModule,
    // DatabaseModule,

    // CreateElementsModule,
    // InfoTablesModule,
    // DropzoneModule, // Ngx-dropzone-wrapper

    // SelectElementTableModule,
  ],
  exports: [components],
})
export class CompanyManagementModule {}
