import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClientFeedbackService {

  public feedBack: Subject<string> = new Subject<string>();

  constructor() { }
}
