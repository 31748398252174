import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExecOptionsWithStringEncoding } from 'node:child_process';
import { RequestErrorService } from 'src/app/core/services/request-error.service';
import { UserService } from 'src/app/core/services/user.service';


@Component({
  selector: 'app-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss']
})
export class ToastMessageComponent implements OnInit {

  errors: any [] = [];
  language: string;
  closeResult = '';

  constructor(
    private requestErrorService: RequestErrorService,
    private userService: UserService,
    private modalService: NgbModal
    ) { }


  open(content) {
    this.modalService.open(content, { centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    this.userService.language.subscribe(language => this.language = language);
    this.requestErrorService.fireError.subscribe(error => this.fireError(error))
  }

  fireError(error){

    // const e: any = { 
    //   msg: error.error.msg,
    //   error: error.error,
    //   'show': true
    // };
    // setTimeout(_ => {
    //   e.show = false;
    //   if(this.errors.filter(ee => ee.show).length == 0){
    //     this.errors = [];
    //   }
    // }, 4000);

    // this.errors.push( e );
  }

  closeError(){

  }

}
