import { Address } from './../model/Address';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { CountryCodeService } from './country-code.service';
import { UtilityService } from './utility.service';
import { baseResponseObject } from '../configs/request.config';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  private url = '/address';
  public addresses: BehaviorSubject<Address[]> = new BehaviorSubject<Address[]>([])

  constructor(
    private api: ApiService,
    private countryCodeService: CountryCodeService,
    private utilityService: UtilityService
    ) { }

  public newEmptyAddress(): Address {
    return {
      detail: '', city: '', country: this.countryCodeService.createEmpty(),
        createdDate: -1, deletedDate: -1, region: '',
        id: '', postalCode: '',
    }
  }

  public createAddressFromRequest(content: any): Address{
    if (!content)
      return this.newEmptyAddress();

    return {
      detail: content.detail,
      city: content.city,
      country: this.countryCodeService.getFromRequest(content.country),
      createdDate: content.created_date,
      deletedDate: content.deleted_date,
      region: content.region,
      id: content.id,
      postalCode: content.postal_code,
    };
  }

  delete(address: Address) {
    const formData = new FormData();
    formData.append('address_id', address.id);

    return this.api.delete(this.url, formData, true);
  }

  get(address: Address) {
    const formData = new FormData();
    formData.append('address_id', address.id);

    return this.api.get(this.url, formData, true);
   }

  getAll() {
    const formData = new FormData();
    const req = this.api.get('/addresses', formData, true);
    req.subscribe((resp: any) => {
      this.addresses.next(
        resp[baseResponseObject].map((d: any) => this.createAddressFromRequest(d.msg))
      );
    })

    return req;
  }
  
  post(address: Address) {
    const formData = new FormData();

    formData.append('detail', address.detail);
    formData.append('city', address.city);
    formData.append('postal_code', address.postalCode);
    formData.append('region', address.region);
    formData.append('country', address.country.name);
    formData.append('country_code', address.country.code);

    return this.api.post(this.url, formData, true);
  }

  put(address: Address) {
    const formData = new FormData();

    formData.append('address_id', address.id);
    formData.append('detail', address.detail);
    formData.append('city', address.city);
    formData.append('postal_code', address.postalCode);
    formData.append('region', address.region);
    formData.append('country', address.country.name);
    formData.append('country_code', address.country.code);

    return this.api.put(this.url, formData, true);
  }

  compareAddress(a1: Address, a2: Address): boolean{
    const parameters = ['detail', 'city', 'createdDate', 'deletedDate', 'region', 'id', 'postalCode'];
    var check = this.utilityService.compareObjsPrams(a1, a2, parameters);
    
    check &&= this.countryCodeService.compare(a1.country, a2.country)

    return check;



    // return a1.detail == a2.detail &&
    // a1.city == a2.city &&
    // this.countryCodeService.compare(a1.country, a2.country) &&
    // a1.createdDate == a2.createdDate &&
    // a1.deletedDate == a2.deletedDate &&
    // a1.region == a2.region &&
    // a1.id == a2.id &&
    // a1.postalCode == a2.postalCode
  }

  selectRequestFunctionAddress(address: Address){
    if(address.id){
      return this.put(address);
    }
    return this.post(address);
  }

  validateAddress(address: Address): boolean{
    var check = true;
    
    if(!address){
      check = false;
    }

    check &&= this.validateDetail(address);
    check &&= this.validateCity(address);
    check &&= this.validatePostalCode(address);
    check &&= this.validatePostalDetail(address);

    return check;

    // return address.detail && address.detail != '' &&
    //   address.city &&
    //   // address.region && address.region != '' &&
    //   this.validatePostalCode(address) &&
    //   this.validatePostalDetail(address)
  }

  validateDetail(address): boolean{
    return address.detail && address.detail != '';
  }

  validateCity(address): boolean{
    return  address.city &&  address.city != '';
  }

  validatePostalCode(address: Address): boolean{
    return this.utilityService.validateString(address.postalCode, 8, 8, [], true);
  }

  validatePostalDetail(address: Address): boolean{
    return this.utilityService.validateString(address.detail, 1, 200, [], true);
  }
}
