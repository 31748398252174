import { HttpErrorResponse } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ExecOptionsWithStringEncoding } from "node:child_process";
import { BaseControlsService } from "src/app/core/services/base-controls.service";
import { RequestErrorService } from "src/app/core/services/request-error.service";
import { UserService } from "src/app/core/services/user.service";

@Component({
  selector: "app-request-message",
  templateUrl: "./request-message.component.html",
  styleUrls: ["./request-message.component.scss"],
})
export class RequestMessageComponent implements OnInit {
  @ViewChild("content") private content: ElementRef;

  error: any;
  language: string;
  closeResult = "";
  time: number = 0;

  constructor(
    private requestErrorService: RequestErrorService,
    private userService: UserService,
    private modalService: NgbModal,
    private baseControlsService: BaseControlsService
  ) {}

  open(size: string) {
    this.modalService
      .open(this.content, { centered: true, size: size })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.reopenCreateModal();
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  reopenCreateModal() {
    setTimeout((_) => {
      this.baseControlsService.reopenCreateModal();
    }, 100);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    this.userService.language.subscribe(
      (language) => (this.language = language)
    );
    this.requestErrorService.fireError.subscribe((error) =>
      this.fireError(error)
    );
  }

  showError() {
    console.log(this.error);
  }

  fireError(error: HttpErrorResponse) {
    var title: string = "Erro - ";
    var msg: string = "";
    var type: number = 0;
    var size: string = "md";
    if (error && error.error && error.status != 402) {
      if (error.status === 401) {
        return;
      }

      if (JSON.stringify(error).includes("Traceback")) {
        title += "Traceback";
        msg = JSON.stringify(error);
        type = 0;
        size = "lg";
      } else if (error.error.msg) {
        title += "Sistema";
        msg = error.error.msg[this.language];
        type = 1;
      } else if (error.error.detail) {
        title += "Django";
        msg = error.error.detail;
        type = 2;
      } else if (error.status == 502) {
        title += "Servidor";
        msg = JSON.stringify(error.message);
        type = 3;
      } else {
        title += "Desconhecido";
        type = 4;
      }

      this.error = {
        title: title,
        msg: (this.time > +new Date() - 300 ? this.error.msg + "\n" : "") + msg,
        type: type,
        error: error,
      };

      this.time = +new Date();

      this.modalService.dismissAll();
      this.open(size);
    }
  }

  copyError(modalBody: HTMLDivElement) {
    // copy to clipboard
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = modalBody.innerText;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);

    modalBody.classList.add("modal-body-animation");
    setTimeout((_) => {
      modalBody.classList.remove("modal-body-animation");
    }, 600);
  }
}
