import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { User } from "../model/User";
import { CompanyService } from "./company.service";
import { WarehouseService } from "./warehouse.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  public user: BehaviorSubject<User> = new BehaviorSubject<User>(
    this.emptyUser()
  );
  public language: BehaviorSubject<string> = new BehaviorSubject<string>("pt");

  debugMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private companyService: CompanyService,
    private warehouseService: WarehouseService
  ) {
    this.user.subscribe((user) => {
      if (user.type == 0) {
        const debugMode = localStorage.getItem("debugMode");
        if (debugMode) {
          this.debugMode.next(debugMode == "true");
        }
      }
    });
  }

  emptyUser(): User {
    return {
      id: "",
      created_date: 0,
      first_name: "",
      last_name: "",
      email: "",
      contact: "",
      activated: false,
      type: 3,
      permissions: {
        companies: [],
        warehouses: [],
      },
    };
  }

  getUserLanguage() {
    const href: string = window.location.href;
    if (href.includes("/en/")) {
      return "en";
    }
    if (href.includes("/pt/")) {
      return "pt";
    }

    return "en";
  }

  setUserFromRequest(msg: any) {
    this.user.next(this.createUserFromRequest(msg));
  }

  createUserFromRequest(msg: any): User {
    let user: User = {
      activated: msg.activated,
      contact: msg.contact,
      created_date: msg.created_date,
      email: msg.email,
      first_name: msg.first_name,
      id: msg.id,
      last_name: msg.last_name,
      type: msg.type,
    };
    if (msg.permissions) {
      user["permissions"] = {
        companies: msg.permissions.companies.map((c) =>
          this.companyService.createCompanyFromRequest(c)
        ),
        warehouses: msg.permissions.warehouses.map((w) =>
          this.warehouseService.createWarehouseFromRequest(w)
        ),
      };
    }

    return user;
  }
}
