import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Company } from "src/app/core/model/Company";
import { CompanyService } from "src/app/core/services/company.service";

@Component({
  selector: "app-select-company",
  templateUrl: "./select-company.component.html",
  styleUrls: ["./select-company.component.scss"],
})
export class SelectCompanyComponent implements OnInit {
  @ViewChild("updateCompanyImageContent") updateCompanyImageContent: ElementRef;

  selectedCompany: Company;
  companies: Company[] = [];
  searchText: string = ''; // Add search text property
  basicModalCloseResult: string = "";

  constructor(
    private companyService: CompanyService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.companyService.selectedCompany.subscribe(
      (c) => (this.selectedCompany = c)
    );
    this.companyService.companies.subscribe(
      (companies) => (this.companies = companies)
    );
  }

  updateCompany(company: Company) {
    this.companyService.selectedCompany.next(company);
    const selectedCompanyId = company.companyId;
    localStorage.setItem("selectedCompanyId", selectedCompanyId);
  }


  getCompanyImage(): string {
    if (this.selectedCompany && this.selectedCompany.logo) {
      return `
      background: url(${this.selectedCompany.logo});
      `;
    }
    return "";
  }

  showChangeImageModal() {
    this.modalService
      .open(this.updateCompanyImageContent, { size: "lg", centered: true })
      .result.then((result) => {
        this.basicModalCloseResult = "Modal closed" + result;
      })
      .catch((res) => {});
  }

  get filteredCompanies(): Company[] {
    if (!this.searchText) {
      return this.companies;
    }

    const lowerCaseSearchText = this.searchText.toLowerCase();

    return this.companies.filter(company =>
      company.name.toLowerCase().includes(lowerCaseSearchText) ||
      (company.nif && company.nif.toLowerCase().includes(lowerCaseSearchText))
    );
  }
}
