import { Component, OnDestroy, OnInit } from "@angular/core";
import { combineLatest } from "rxjs";
import { filter } from "rxjs/operators";
import { Company } from "src/app/core/model/Company";
import { CompanyService } from "src/app/core/services/company.service";
import { UserService } from "src/app/core/services/user.service";

@Component({
  selector: "app-company-management",
  templateUrl: "./company-management.component.html",
  styleUrls: ["./company-management.component.scss"],
})
export class CompanyManagementComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  subFilteredRequest: any = null;
  subCombineLatest: any = null;
  companies: Company[] = [];
  numTotalData: number = 0;
  tableLoading: boolean = true;
  companyType:string = "-1";
  companyTypeOptions: { value: string; label: string }[] = [];
  user: any;
  hideButton: boolean = false;
  constructor(
    private userService: UserService,
    private companyService: CompanyService
  ) {}


  ngOnInit(): void {
    this.subCombineLatest = combineLatest([this.userService.user])
      .pipe(filter(([user]) => user.id != ""))
      .subscribe(() => this.getFiltered(0, 20));
  
    this.createOptions();
  
    this.userService.user.subscribe((user) => {
      this.user = user;
    });
  }
  
  ngOnDestroy(): void {
    this.unsubscribeFilteredRequest();
  }


  createOptions() {
    this.companyTypeOptions = [
      { value: "1", label: "Ativas" },
      { value: "0", label: "Suspensas" },
      { value: "True", label: "Demonstração" },
      { value: "False", label: "Produção" },
    ];
  }
  updateTableFunc(data) {
    const min = data.page * data.numPerPage;
    const max = (data.page + 1) * data.numPerPage;
    const orderedList = data.ordered_list;
    const textFilter = data.text_filter;
    const reset = data.reset
    console.log("reset",data);
    this.getFiltered(min, max,reset, orderedList, textFilter);
  }

  unsubscribeFilteredRequest() {
    if (this.subCombineLatest) {
      this.subCombineLatest.unsubscribe();
      this.subCombineLatest = null;
    }
    if (this.subFilteredRequest) {
      this.subFilteredRequest.unsubscribe();
      this.subFilteredRequest = null;
    }
  }

  formatTextFilter(textFilter) {
    textFilter = textFilter ? textFilter : [];
    if (this.companyType === "True" || this.companyType === "False") {
      console.log("start",this.companyType);
      textFilter.push({
        field: "demo",
        type: "boolean",
        value: this.companyType,
      });
      console.log("start2",textFilter);
      return textFilter;
    }else if (this.companyType === "1") {
      textFilter.push({
        field: "deleted_date",
        type: "null",
        value: null,
      });
      return textFilter;
    } else if (this.companyType === "0") {
      textFilter.push({
        field: "deleted_date",
        type: "not_null",
        value: null,
      });
      return textFilter;
    }
    return textFilter
  }

  
  getFiltered(min, max,reset=false,orderedList?, textFilter?) {
    this.hideButton = true;
    console.log("start",textFilter);
    // [{"field":"nif","type":"string","value":"255"}]
    
    textFilter = this.formatTextFilter(textFilter);
    
    console.log("end",textFilter);

  
    this.unsubscribeFilteredRequest();
    if (reset==true) {
      this.loading = true;
      this.companies = [];
    }

    this.subFilteredRequest = this.companyService
      .getFiltered(min, max, orderedList, textFilter)
      .subscribe(({ companies_number, companies }) => {
        this.loading = false;
        this.companies = this.companies.concat(companies);
        this.numTotalData = companies_number;
        this.hideButton = false;

      });
  }

  resetTable() {
    this.tableLoading = true;
    this.getFiltered(0,20,true);
  }

}
