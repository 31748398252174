import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { CropperComponent } from "angular-cropperjs";
import { Company } from "src/app/core/model/Company";
import { CompanyService } from "src/app/core/services/company.service";
import { UtilityService } from "src/app/core/services/utility.service";

@Component({
  selector: "app-update-company-image",
  templateUrl: "./update-company-image.component.html",
  styleUrls: ["./update-company-image.component.scss"],
})
export class UpdateCompanyImageComponent implements OnInit {
  @ViewChild("angularCropper") angularCropper: CropperComponent;
  @ViewChild("inputImage") inputImage: ElementRef;

  @Input() company: Company;
  selectedCompany: Company;
  loading: boolean = false;

  imageFileName: string = "";
  imageUrl: any = "assets/images/placeholder.jpg";
  resultImage: any;
  config = {
    zoomable: true,
    aspectRatio: 16 / 9,
    data: {
      width: 300,
    },
    crop: (event) => {
      var width = event.detail.width;
      this.cropImage();
    },
  };

  constructor(
    private companyService: CompanyService,
    private utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    this.imageUrl = this.company.logo || "assets/images/placeholder.jpg";
    this.companyService.selectedCompany.subscribe(
      (company) => (this.selectedCompany = company)
    );
  }

  openFileBrowser(event: any) {
    this.inputImage.nativeElement.click();
  }

  handleFileInput(event: any) {
    if (event.target.files.length) {
      let fileName = event.target.files[0].name;
      this.imageFileName = fileName;
      var fileTypes = ["jpg", "jpeg", "png"]; //acceptable file types
      var extension = event.target.files[0].name.split(".").pop().toLowerCase(), //file extension from input file
        isSuccess = fileTypes.indexOf(extension) > -1; //is extension in acceptable types
      if (isSuccess) {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (event.target.result) {
            this.angularCropper.imageUrl = event.target.result;
          }
        };
        reader.readAsDataURL(event.target.files[0]);
      } else {
        //no
        alert("Selected file is not an image. Please select an image file.");
      }
    }
  }

  cropImage() {
    this.resultImage = this.angularCropper.cropper
      .getCroppedCanvas()
      .toDataURL();
  }

  onSubmit() {
    let fileName: any = this.imageFileName.split(".");
    fileName =
      fileName[fileName.length - 2] + "." + fileName[fileName.length - 1];
    const file = this.dataURLtoFile(this.resultImage, fileName);
    const formData = this.companyService.createCompanyFormDate(this.company);

    this.loading = true;
    formData.append("company_id", this.company.companyId);
    formData.append("image", file, file.name);

    this.companyService.updateImage(formData).subscribe((resp) => {
      this.company.logo = this.resultImage;
      this.loading = false;
      if (this.company.companyId == this.selectedCompany.companyId) {
        this.companyService.selectedCompany.next(this.company);
      }
    });
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
}
