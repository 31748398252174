import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Company } from "src/app/core/model/Company";
import { CompanyService } from "src/app/core/services/company.service";

@Component({
  selector: 'app-company-logo',
  templateUrl: './company-logo.component.html',
  styleUrls: ['./company-logo.component.scss']
})
export class CompanyLogoComponent implements OnInit {
  @ViewChild("updateCompanyImageContent") updateCompanyImageContent: ElementRef;

  selectedCompany: Company;

  constructor(    
    private companyService: CompanyService,
    private modalService: NgbModal
) { }

  ngOnInit(): void {
    this.companyService.selectedCompany.subscribe(
      (c) => (this.selectedCompany = c)
    );

  }

  updateCompany(company: Company) {
    this.companyService.selectedCompany.next(company);
    const selectedCompanyId = company.companyId;
    localStorage.setItem("selectedCompanyId", selectedCompanyId);
  }
  
  getCompanyImage(): string {
    if (this.selectedCompany && this.selectedCompany.logo) {
      return `
      background: url(${this.selectedCompany.logo});
      `;
    }
    return "";
  }
}
