import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from 'src/app/core/model/User';
import { UserService } from 'src/app/core/services/user.service';
import { UserAdminService } from 'src/app/core/services/user-admin.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { BaseControlsService } from 'src/app/core/services/base-controls.service';

@Component({
  selector: 'change-password-popup',
  templateUrl: './change-password-popup.component.html',
  styleUrls: ['./change-password-popup.component.scss']
})
export class ChangePasswordPopupComponent implements OnInit {
  showPopup: boolean = false;
  user: User = this.userService.emptyUser();
  popupDateTime: Date | null = null; 
  form: FormGroup;
  
  // Toggle variables for password visibility
  showOldPassword: boolean = false;
  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;

  @ViewChild("confirmChangePasswordSwal") private confirmChangePasswordSwal: SwalComponent;
  @ViewChild("errorAtualPasswordSwal") private errorAtualPasswordSwal: SwalComponent;
  @ViewChild("errorNewPasswordSwal") private errorNewPasswordSwal: SwalComponent;

  constructor(
    fb: FormBuilder,
    private userService: UserService,
    private userAdminService: UserAdminService,
    private modalService: NgbModal,
    private baseControlsService: BaseControlsService,
  ) {    
    this.form = fb.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.userService.user.subscribe(u => this.user = u);
    this.userInfo();
  }

  get oldPassword() { return this.form.get('oldPassword'); }
  get newPassword() { return this.form.get('newPassword'); }
  get confirmPassword() { return this.form.get('confirmPassword'); }

  toggleOldPasswordVisibility() {
    this.showOldPassword = !this.showOldPassword;
  }

  toggleNewPasswordVisibility() {
    this.showNewPassword = !this.showNewPassword;
  }

  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  async closePopup() {
    const respCompany = await this.userAdminService.put_password_exp(this.user.id, String(Math.floor(this.popupDateTime.getTime() / 1000))).toPromise();
    this.showPopup = false;

    location.reload();

  }

  passwordsMatch(): boolean {
    return this.newPassword?.value === this.confirmPassword?.value;
  }

  async oldPasswordsMatch(): Promise<boolean> {
    const oldPassword = this.oldPassword?.value;
    try {
      const res = await this.userAdminService.post_check_password(this.user.id, oldPassword).toPromise();
      return String(res.type) === "success";
    } catch (error) {
      console.error('Error checking old password', error);
      return false;
    }
  }

  async userInfo() {
    const res: any = await this.userAdminService.get(this.user).toPromise();
    this.popupDateTime = new Date(res.msg.password_exp * 1000);
    const delay = this.popupDateTime.getTime() - Date.now();
    if (delay < 0) {
      setTimeout(() => this.showPopup = true, delay);
    } else {
      console.log('Specified time is in the past.');
    }
  }

  async updatePassword() {
    if (this.form.valid && this.passwordsMatch()) {
      const oldPasswordMatches = await this.oldPasswordsMatch();
      if (oldPasswordMatches) {
        try {
          await this.userAdminService.put_password(this.user.id, this.newPassword?.value).toPromise();
          this.finishUpdatePassword();
        } catch (error) {
          console.error('Error changing password', error);
        }
      } else {
        this.errorAtualPasswordSwal.fire();
      }
    } else {
      this.errorNewPasswordSwal.fire();
    }
  }
  
  finishUpdatePassword() {
    this.modalService.dismissAll();
    this.confirmChangePasswordSwal.fire().then(() => {
      this.closePopup(); // Call closePopup after the Swal popup is closed
    });
    this.baseControlsService.isLoading.next(false);
    this.form.reset();
  }
}
