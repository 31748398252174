import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { NgxMaskModule } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './core/guard/auth.guard';
import { ErrorModule } from './views/error/error.module';
import { RequestMessageComponent } from './views/error/request-message/request-message.component';
import { LayoutModule } from './views/layout/layout.module';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';
import { FadeInComponent } from './core/animations/fade-in/fade-in.component';

@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    FadeInComponent,
    
    // CustomerModalBodyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    FormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    ErrorModule
  ],
  providers: [
    AuthGuard,
    // {
    //   provide: HIGHLIGHT_OPTIONS, // https://www.npmjs.com/package/ngx-highlightjs
    //   useValue: {
    //     coreLibraryLoader: () => import('highlight.js/lib/core'),
    //     languages: {
    //       xml: () => import('highlight.js/lib/languages/xml'),
    //       typescript: () => import('highlight.js/lib/languages/typescript'),
    //       scss: () => import('highlight.js/lib/languages/scss'),
    //     }
    //   }
    // }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
