import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestMessageComponent } from './request-message/request-message.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbAlertModule, NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { CustomFormsModule } from 'ngx-custom-validators';
import { NgxMaskModule } from 'ngx-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { TagInputModule } from 'ngx-chips';
import { ColorPickerModule } from 'ngx-color-picker';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { ToastMessageComponent } from './toast-message/toast-message.component';

@NgModule({
  declarations: [RequestMessageComponent, ToastMessageComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbPaginationModule,
    NgbAlertModule,
    SweetAlert2Module.forRoot(),
    SweetAlert2Module,
    SweetAlert2Module.forChild(),
    NgbModule,

    ReactiveFormsModule,
    CustomFormsModule, // Ngx-custom-validators
    NgxMaskModule.forRoot({ validation: true }), // Ngx-mask
    NgSelectModule, // Ng-select
    TagInputModule, // Ngx-chips
    ColorPickerModule, // Ngx-color-picker
    DropzoneModule, // Ngx-dropzone-wrapper
  ],
  exports: [RequestMessageComponent, ToastMessageComponent],
})
export class ErrorModule {}
