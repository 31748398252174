import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: {
      en: 'Documentations',
      pt: 'Administração'
    },
    isTitle: true,
    userType: [1,2],
  },

  {
    label: {
      en: 'Companies',
      pt: 'Empresas'
    },
    icon: 'fas fa-building',
    fontawesomeIcon: true,
    link: 'admin/company-management',
    userType: [1,2],
  },
  {
    label: {
      en: 'Company Logo',
      pt: 'Logótipo'
    },
    icon: 'fas fa-image',
    fontawesomeIcon: true,
    link: 'admin/company-logo',
    userType: [1,2],
  },
  {
    label: {
      en: 'Instructions',
      pt: 'Instruções'
    },
    icon: 'fas fa-book',
    fontawesomeIcon: true,
    link: 'admin/instructions',
    userType: [1,2],
  },
  // {
  //   label: {
  //     en: 'SAF-T PT',
  //     pt: 'SAF-T PT'
  //   },
  //   icon: 'far fa-file-code',
  //   fontawesomeIcon: true,
  //   link: 'admin/saf-t',
  //   userType: 0,
  // },
  // {
  //   label: {
  //     en: 'Update Data',
  //     pt: 'Importar Dados'
  //   },
  //   icon: 'fas fa-upload',
  //   fontawesomeIcon: true,
  //   link: 'admin/inport-data-file',
  //   userType: 0,
  // },

  // CLIENT ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------
 
  {
    label: {
      en: 'Management',
      pt: 'Gestão'
    },
    isTitle: true,
    userType: [1,2],
  },
  {
    label: {
      en: 'Users',
      pt: 'Utilizadores'
    },
    icon: 'fas fa-user',
    fontawesomeIcon: true,
    link: 'admin/user-management',
    userType: [1,2],
  },
  {
    label: {
      en: 'Warehouses',
      pt: 'Armazéns'
    },
    icon: 'fas fa-warehouse',
    fontawesomeIcon: true,
    link: 'admin/warehouse-management',
    userType: [1,2],
  },
  {
    label: {
      en: 'Customers',
      pt: 'Clientes'
    },
    icon: "fas fa-user-friends",
    fontawesomeIcon: true,
    link: 'admin/customer-management',
    userType: [1,2],
  },
  {
    label: {
      en: 'Item',
      pt: 'Artigos'
    },
    icon: 'fa fa-shopping-bag',
    fontawesomeIcon: true,
    link: 'admin/item-management',
    userType: [1,2],
  },
  {
    label: {
      en: 'AT Codes',
      pt: 'Comunicações AT'
    },
    icon: 'fas fa-tags',
    fontawesomeIcon: true,
    link: 'admin/at-management',
    userType: [1,2],
  },

   // {
  //   label: {
  //     en: 'Suppliers',
  //     pt: 'Fornecedores'
  //   },
  //   icon: 'fas fa-parachute-box',
  //   fontawesomeIcon: true,
  //   link: 'admin/supplier-management',
  //   userType: 0,
  // },

  {
    label: {
      en: 'Taxes',
      pt: 'Taxas de IVA'
    },
    icon: 'fas fa-euro-sign',
    fontawesomeIcon: true,
    link: 'admin/tax-management',
    userType: [1,2],
  },
  {
    label: {
      en: 'Billing System',
      pt: 'Sistema de Faturação'
    },
    isTitle: true,
    userType: [1,2],
  },
  {
    label: {
      en: 'Invoices',
      pt: 'Faturas'
    },
    icon: 'fas fa-file-invoice-dollar',
    fontawesomeIcon: true,
    userType: [1,2],
    link: 'admin/invoice',
  },
  {
    label: {
      en: 'Receipts',
      pt: 'Recibos'
    },
    icon: 'fas fa-receipt',
    fontawesomeIcon: true,
    link: 'admin/receipt',
    userType: [1,2],
  },
  {
    label: {
      en: 'Credit Notes',
      pt: 'Notas de Crédito'
    },
    icon: 'fas fa-file-invoice',
    fontawesomeIcon: true,
    link: 'admin/credit-note',
    userType: [1,2],
  },
  {
    label: {
      en: 'Current Account',
      pt: 'Conta Corrente'
    },
    icon: 'fas fa-wallet',
    fontawesomeIcon: true,
    link: 'admin/current-account',
    userType: [0,1,2],
  },
  // {
  //   label: {
  //     en: 'Cash Flow',
  //     pt: 'Fluxo de Caixa'
  //   },
  //   icon: 'fas fa-money-bill-wave-alt',
  //   fontawesomeIcon: true,
  //   link: 'admin/cash-flow',
  //   userType: 0,
  // },
    
  {
    label: {
      en: 'SAF-T',
      pt: 'SAF-T'
    },
    icon: 'far fa-file-code',
    fontawesomeIcon: true,
    link: 'admin/saf-t',
    userType: [1,2],
  },
      
  {
    label: {
      en: 'Relatório IVA',
      pt: 'Relatório IVA'
    },
    icon: 'far fa-file-code',
    fontawesomeIcon: true,
    link: 'admin/iva-report',
    userType: [1,2],
  },
  // {
  //   label: {
  //     en: 'Update Data',
  //     pt: 'Importar Dados'
  //   },
  //   icon: 'fas fa-upload',
  //   fontawesomeIcon: true,
  //   link: 'admin/inport-data-file',
  //   userType: [1,2],
  // },

  {
    label: {
      en: 'Settings',
      pt: 'Configurações'
    },
    isTitle: true,
    userType: [1,2],
  },
  {
    label: {
      en: 'Change Password',
      pt: 'Mudar Palavra-Passe'
    },
    icon: 'fas fa-key',
    fontawesomeIcon: true,
    link: 'admin/change-password',
    userType: [1,2],
  },
  {
    label: {
      en: 'Contact',
      pt: 'Contactos'
    },
    icon: 'fas fa-question-circle',
    fontawesomeIcon: true,
    link: 'admin/support',
    userType: [1,2,3,4,5],
  },  
  {
    label: {
      en: 'Admin Documentation',
      pt: 'Documentação'
    },
    fontawesomeIcon: true,
    icon: 'fas fa-book',
    link: 'doc',
    userType: [0],
  },

// ---------------- ---------------- ---------------- ---------------- ---------------- ----------------

  // {
  //   label: 'Sistema Faturação',
  //   isTitle: true
  // },
  // {
  //   label: 'Mapa de Caixa',
  //   icon: 'codesandbox',
  // },
  // {
  //   label: 'Invoice',
  //   icon: 'dollar-sign',
  //   subItems: [

  //     {
  //       label: 'Invoice',
  //       link: 'admin/invoice',
  //     },
  //     {
  //       label: 'Invoice Receipt',
  //       link: 'admin/invoice-receipt',
  //     },
  //   ]
  // },
  // {
  //   label: 'Guia',
  //   // icon: 'mdi mdi-file-document',
  //   icon: 'clipboard',

  // },

  // {
  //   label: 'Nota de Credito',
  //   // icon: 'mdi mdi-file-document',
  //   icon: 'clipboard',

  // },

  // {
  //   label: 'Recibo',
  //   // icon: 'mdi mdi-file-document',
  //   icon: 'clipboard',

  // },

  // //
  // {
  //   label: 'Main',
  //   isTitle: true
  // },
  // {
  //   label: 'Dashboard',
  //   icon: 'home',
  //   link: '/dashboard'
  // },
  // {
  //   label: 'Web Apps',
  //   isTitle: true
  // },
  // {
  //   label: 'Email',
  //   icon: 'mail',
  //   subItems: [
  //     {
  //       label: 'Inbox',
  //       link: '/apps/email/inbox',
  //     },
  //     {
  //       label: 'Read',
  //       link: '/apps/email/read'
  //     },
  //     {
  //       label: 'Compose',
  //       link: '/apps/email/compose'
  //     },
  //   ]
  // },
  // {
  //   label: 'Chat',
  //   icon: 'message-square',
  //   link: '/apps/chat',
  // },
  // {
  //   label: 'Calendar',
  //   icon: 'calendar',
  //   link: '/apps/calendar',
  //   badge: {
  //     variant: 'primary',
  //     text: 'New',
  //   }
  // },
  // {
  //   label: 'Components',
  //   isTitle: true
  // },
  // {
  //   label: 'UI Kit',
  //   icon: 'feather',
  //   subItems: [
  //     {
  //       label: 'Alerts',
  //       link: '/ui-components/alerts',
  //     },
  //     {
  //       label: 'Badges',
  //       link: '/ui-components/badges',
  //     },
  //     {
  //       label: 'Breadcrumbs',
  //       link: '/ui-components/breadcrumbs',
  //     },
  //     {
  //       label: 'Buttons',
  //       link: '/ui-components/buttons',
  //     },
  //     {
  //       label: 'Button group',
  //       link: '/ui-components/button-group',
  //     },
  //     {
  //       label: 'Cards',
  //       link: '/ui-components/cards',
  //     },
  //     {
  //       label: 'Carousel',
  //       link: '/ui-components/carousel',
  //     },
  //     {
  //       label: 'Collapse',
  //       link: '/ui-components/collapse',
  //     },
  //     {
  //       label: 'Datepicker',
  //       link: '/ui-components/datepicker',
  //     },
  //     {
  //       label: 'Dropdowns',
  //       link: '/ui-components/dropdowns',
  //     },
  //     {
  //       label: 'List group',
  //       link: '/ui-components/list-group',
  //     },
  //     {
  //       label: 'Media object',
  //       link: '/ui-components/media-object',
  //     },
  //     {
  //       label: 'Modal',
  //       link: '/ui-components/modal',
  //     },
  //     {
  //       label: 'Navs',
  //       link: '/ui-components/navs',
  //     },
  //     {
  //       label: 'Navbar',
  //       link: '/ui-components/navbar',
  //     },
  //     {
  //       label: 'Pagination',
  //       link: '/ui-components/pagination',
  //     },
  //     {
  //       label: 'Popovers',
  //       link: '/ui-components/popovers',
  //     },
  //     {
  //       label: 'Progress',
  //       link: '/ui-components/progress',
  //     },
  //     {
  //       label: 'Rating',
  //       link: '/ui-components/rating',
  //     },
  //     {
  //       label: 'Scrollbar',
  //       link: '/ui-components/scrollbar',
  //     },
  //     {
  //       label: 'Spinners',
  //       link: '/ui-components/spinners',
  //     },
  //     {
  //       label: 'Timepicker',
  //       link: '/ui-components/timepicker',
  //     },
  //     {
  //       label: 'Tooltips',
  //       link: '/ui-components/tooltips',
  //     },
  //     {
  //       label: 'Typeadhed',
  //       link: '/ui-components/typeahead',
  //     },
  //   ]
  // },
  // {
  //   label: 'Advanced UI',
  //   icon: 'anchor',
  //   subItems: [
  //     {
  //       label: 'Cropper',
  //       link: '/advanced-ui/cropper',
  //     },
  //     {
  //       label: 'Owl carousel',
  //       link: '/advanced-ui/owl-carousel',
  //     },
  //     {
  //       label: 'Sweet alert',
  //       link: '/advanced-ui/sweet-alert',
  //     },
  //   ]
  // },
  // {
  //   label: 'Forms',
  //   icon: 'file-text',
  //   subItems: [
  //     {
  //       label: 'Basic elements',
  //       link: '/form-elements/basic-elements'
  //     },
  //     {
  //       label: 'Advanced elements',
  //       subItems: [
  //         {
  //           label: 'Form validation',
  //           link: '/advanced-form-elements/form-validation'
  //         },
  //         {
  //           label: 'Input mask',
  //           link: '/advanced-form-elements/input-mask'
  //         },
  //         {
  //           label: 'Ng-select',
  //           link: '/advanced-form-elements/ng-select'
  //         },
  //         {
  //           label: 'Ngx-chips',
  //           link: '/advanced-form-elements/ngx-chips'
  //         },
  //         {
  //           label: 'Ngx-color-picker',
  //           link: '/advanced-form-elements/ngx-color-picker'
  //         },
  //         {
  //           label: 'Ngx-dropzone',
  //           link: '/advanced-form-elements/ngx-dropzone-wrapper'
  //         },
  //       ]
  //     },
  //     {
  //       label: 'Editors',
  //       link: '/form-elements/editors'
  //     },
  //     {
  //       label: 'Wizard',
  //       link: '/form-elements/wizard'
  //     },
  //   ]
  // },
  // {
  //   label: 'Charts & graphs',
  //   icon: 'pie-chart',
  //   subItems: [
  //     {
  //       label: 'ApexCharts',
  //       link: '/charts-graphs/apexcharts',
  //     },
  //     {
  //       label: 'ChartJs',
  //       link: '/charts-graphs/chartjs',
  //     },
  //   ]
  // },
  // {
  //   label: 'Tables',
  //   icon: 'layout',
  //   subItems: [
  //     {
  //       label: 'Basic tables',
  //       link: '/tables/basic-table',
  //     },
  //     {
  //       label: 'Data table',
  //       link: '/tables/data-table',
  //     }
  //   ]
  // },
  // {
  //   label: 'Icons',
  //   icon: 'smile',
  //   subItems: [
  //     {
  //       label: 'Feather icons',
  //       link: '/icons/feather-icons',
  //     },
  //     {
  //       label: 'Flag icons',
  //       link: '/icons/flag-icons',
  //     },
  //     {
  //       label: 'Mdi icons',
  //       link: '/icons/mdi-icons',
  //     }
  //   ]
  // },
  // {
  //   label: 'Pages',
  //   isTitle: true
  // },
  // {
  //   label: 'Special pages',
  //   icon: 'book',
  //   subItems: [
  //     {
  //       label: 'Blank page',
  //       link: '/general/blank-page',
  //     },
  //     {
  //       label: 'Faq',
  //       link: '/general/faq',
  //     },
  //     {
  //       label: 'Invoice',
  //       link: '/general/invoice',
  //     },
  //     {
  //       label: 'Profile',
  //       link: '/general/profile',
  //     },
  //     {
  //       label: 'Pricing',
  //       link: '/general/pricing',
  //     },
  //     {
  //       label: 'Timeline',
  //       link: '/general/timeline',
  //     }
  //   ]
  // },
  // {
  //   label: 'Authentication',
  //   icon: 'unlock',
  //   subItems: [
  //     {
  //       label: 'Login',
  //       link: '/auth/login',
  //     },
  //     {
  //       label: 'Register',
  //       link: '/auth/register',
  //     },
  //   ]
  // },
  // {
  //   label: 'Error',
  //   icon: 'cloud-off',
  //   subItems: [
  //     {
  //       label: '404',
  //       link: '/error/404',
  //     },
  //     {
  //       label: '500',
  //       link: '/error/500',
  //     },
  //   ]
  // },
];
