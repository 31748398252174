import { UserService } from "./../services/user.service";
import { Injectable } from "@angular/core";
import {
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem("authToken")) {
      // logged in so return true
      if (this.userService.user.getValue()) {
        this.authService.userInfo();
        return true;
      }
    }

    // not logged in so redirect to login page with the return url
    if (localStorage.getItem("page")) {
      localStorage.setItem("page", this.router.url);
    }
    this.router.navigate(["/auth/login"], {
      queryParams: { returnUrl: state.url },
    });
    return false;
  }
}
