import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseControlsService } from './base-controls.service';
import { RequestErrorService } from './request-error.service';
import { UtilityService } from './utility.service';

/**
 * Service for making API requests.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private authToken: string;
  // _url: string = `http://192.168.10.98:24304/api`; // PROD
  // _url: string = `https://taxi.geolink.pt:24304/api`; // PROD
  // _url: string = `http://192.168.10.98:5002/api`; // PROD
  _url: string = `https://tlb.geolink.pt/api`; // PROD

  // CASH
  // private cache: Record<
  //   string,
  //   {
  //     cache: any;
  //     cachedObservable: Observable<any>;
  //   }
  // > = {};
  // private cachedObservable: Record<string, Observable<any>> = {};

  constructor(
    private http: HttpClient,
    private baseControlsService: BaseControlsService,
    private requestErrorService: RequestErrorService,
    private utilityService: UtilityService
  ) {}

  onLogout = () => {};

  // clearCache(url) {
  //   if (this.cache[url]) {
  //     this.cache[url].cache = null;
  //     this.cache[url].cachedObservable = null;
  //   }
  // }

  /**
   * Removes the authentication token.
   */
  removeAuthToken() {
    this.authToken = '';
  }

  /**
   * Retrieves HTTP options for making requests.
   * @param isAuthToken - Whether to include the authentication token in the headers.
   * @returns HTTP options object.
   */
  private getHttpOptions(isAuthToken: boolean): any {
    if (!isAuthToken) {
      return {};
    }

    if (!this.authToken) {
      this.authToken = localStorage.getItem('authToken');
    }

    const authToken = this.authToken;
    return {
      headers: {
        'X-Forwarded-For': authToken,
      },
    };
  }

  /**
   * Handles HTTP errors.
   * @param error - The error response.
   * @returns Observable with error message.
   */
  private handleError(error: HttpErrorResponse) {
    if (error.status === 401) {
      this.onLogout();
    }

    this.isLoading(false);
    this.requestErrorService.fireError.next(error);
    // this.clientFeedbackService.feedBack.next(error.error.class || '')

    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    }
    return throwError('Something bad happened; please try again later.');
  }

  /**
   * Sets loading state.
   * @param val - Loading state.
   */
  public isLoading(val: boolean) {
    this.baseControlsService.isLoading.next(val);
  }

  /**
   * Performs HTTP DELETE request.
   * @param url - The URL for the request.
   * @param formData - The form data to be sent.
   * @param isAuthToken - Whether to include the authentication token.
   * @returns Observable with the response.
   */
  delete(url: string, formData: FormData, isAuthToken: boolean) {
    let httpOptions = this.getHttpOptions(isAuthToken);
    httpOptions['body'] = formData;

    this.baseControlsService.isLoading.next(true);
    return this.http
      .delete<any>(this._url + url, httpOptions)
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * Performs HTTP GET request.
   * @param url - The URL for the request.
   * @param formData - The form data to be sent.
   * @param isAuthToken - Whether to include the authentication token.
   * @param loading - Whether to show loading indicator.
   * @returns Observable with the response.
   */
  get(url: string, formData: FormData, isAuthToken: boolean, loading?) {
    const httpOptions = this.getHttpOptions(isAuthToken);

    formData.append("test", 'test')

    httpOptions['body'] = formData;

    if (loading !== undefined && loading) {
      this.baseControlsService.isLoading.next(true);
    }
    if (url.includes('recover_password')) {
      return this.http
        .get<any>(this._url + url, httpOptions)      
    }
    return this.http
      .get<any>(this._url + url, httpOptions)
      .pipe(catchError((error) => this.handleError(error)));

  }

  /**
   * Performs HTTP GET request.
   * @param url - The URL for the request.
   * @param formData - The form data to be sent.
   * @param isAuthToken - Whether to include the authentication token.
   * @returns Observable with the response.
   */
  getAll(url: string, formData: FormData, isAuthToken: boolean) {
    const httpOptions = this.getHttpOptions(isAuthToken);
    this.baseControlsService.isLoading.next(false);

    console.log(url, httpOptions)
    return this.http
      .get<any>(this._url + url, httpOptions)
      .pipe(catchError((error) => this.handleError(error)));
    // .pipe(tap(res => this.cache[url].cache = res), share(), finalize(() => this.cache[url] = null));
  }

  /**
   * Performs HTTP POST request.
   * @param url - The URL for the request.
   * @param formData - The form data to be sent.
   * @param isAuthToken - Whether to include the authentication token.
   * @param loading - Whether to show loading indicator.
   * @returns Observable with the response.
   */
  post(url: string, formData: FormData, isAuthToken: boolean, loading?) {
    const httpOptions = this.getHttpOptions(isAuthToken);
    if (loading !== undefined && loading) {
      this.baseControlsService.isLoading.next(true);
    }

    console.log(url, httpOptions)
    return this.http
      .post<any>(this._url + url, formData, httpOptions)
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * Performs HTTP POST request with filtered parameters.
   * @param url - The URL for the request.
   * @param formData - The form data to be sent.
   * @param minLimit - The minimum limit for results.
   * @param maxLimit - The maximum limit for results.
   * @param orderedList - Optional parameter for ordered list.
   * @param textFilter - Optional parameter for text filter.
   * @param filter_type - Optional parameter for filter type (AND or OR).
   * @returns Observable with the response.
   */
  public getFiltered(
    url,
    formData: FormData,
    minLimit: number,
    maxLimit: number,
    orderedList?: any,
    textFilter?: any,
    filter_type?: any
  ) {
    formData.append('min_limit', minLimit + '');
    formData.append('max_limit', maxLimit + '');

    if (orderedList && orderedList.length > 0) {
      formData.append('ordered_list', JSON.stringify(orderedList));
    }
    if (textFilter && textFilter.length > 0) {
      formData.append('text_filter', JSON.stringify(textFilter));
    }
    if (filter_type) {
      formData.append('filter_type', filter_type);
    } else {
      if (
        textFilter &&
        textFilter.length >= 1 &&
        this.utilityService.unique(textFilter.map((tf) => tf.value)).length == 1
      ) {
        formData.append('filter_type', 'OR');
      } else {
        formData.append('filter_type', 'AND');
      }
    }

    return this.post(url, formData, true, false);
  }

  /**
   * Performs HTTP PUT request.
   * @param url - The URL for the request.
   * @param formData - The form data to be sent.
   * @param isAuthToken - Whether to include the authentication token.
   * @param loading - Whether to show loading indicator.
   * @returns Observable with the response.
   */
  put(url: string, formData: FormData, isAuthToken: boolean, loading?) {
    const httpOptions = this.getHttpOptions(isAuthToken);
    if (loading !== undefined && loading) {
      this.baseControlsService.isLoading.next(true);
    }
    return this.http
      .put<any>(this._url + url, formData, httpOptions)
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * Performs HTTP POST request with XML response type.
   * @param url - The URL for the request.
   * @param formData - The form data to be sent.
   * @param isAuthToken - Whether to include the authentication token.
   * @returns Observable with the response.
   */
  postXml(url: string, formData: FormData, isAuthToken: boolean) {
    let httpOptions = this.getHttpOptions(isAuthToken);
    httpOptions['responseType'] = 'text';
    httpOptions['charset'] = 'windows-1252';
    // this.baseControlsService.isLoading.next(true)
    return this.http
      .post<any>(this._url + url, formData, httpOptions)
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * Performs HTTP POST request with PDF response type.
   * @param url - The URL for the request.
   * @param formData - The form data to be sent.
   * @param isAuthToken - Whether to include the authentication token.
   * @returns Observable with the response.
   */
  postPdf(url: string, formData: FormData, isAuthToken: boolean) {
    let httpOptions = this.getHttpOptions(isAuthToken);
    httpOptions['responseType'] = 'blob';

    // this.baseControlsService.isLoading.next(true)
    return this.http.post<Blob>(this._url + url, formData, httpOptions).pipe(
      catchError(async (error) => {
        const e = await error.error.text();
        return this.handleError(e);
      })
    );
  }
}
