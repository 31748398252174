import { ClientFeedbackService } from './../../../core/services/client-feedback.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-client-feedback',
  templateUrl: './client-feedback.component.html',
  styleUrls: ['./client-feedback.component.scss']
})
export class ClientFeedbackComponent implements OnInit {

  @ViewChild('error') private error: SwalComponent;

  constructor(private clientFeedbackService: ClientFeedbackService) { }

  ngOnInit(): void {

    this.clientFeedbackService.feedBack.subscribe(feedBack => {
      switch(feedBack){
        default: this.error.fire()
      }
    })
  }

}
